<template>
	<div class="">
		<div class="home_right">
			
			<div class="right_title">
				<div class="title_left" >
					<!-- 消息 -->{{$t('home.XX')}}
				</div>
				<!-- <div class="title_right">
					<img src="../../assets/img/home_right_menu.png" class="menu1" alt="">
					<img src="../../assets/img/home_right_menu2.png" class="menu2" alt="">
				</div> -->
				<!-- <div class="title_right" @click="del">清空</div> -->
			</div>
			<div class="right_search">
				<img src="../../assets/img/search.png" class="search" alt="" >
				<input type="text" :placeholder="$t('home.SS')" v-model.trim="keyword" class="search_input">
			</div>
			<div style="overflow-y: auto;max-height: 600px;">
				<div v-for="item in list" :key="item.conversationID" >
					<div class="right_list" v-if="item.type=='C2C'" @click.stop="sendmsg(item.userProfile.userID)">
						<div class="list_img">
							<img :src="item.userProfile.avatar"   style="object-fit: cover;" alt="" v-if="item.userProfile.avatar">
							<div class="num" v-if="item.unreadCount>0">{{item.unreadCount}}</div>
						</div>
						
						<div class="list_right">
							<div class="right_user" v-if="item.userProfile.nick">
								{{item.userProfile.nick}}
							</div>
							<div class="right_time">
								<div class="time_msg">
									{{item.lastMessage.messageForShow}}
								</div>
								
								<div style="margin-left: 10px;">{{$util.timestampToTime(item.lastMessage.lastTime)}}</div>
							</div>
						</div>
					</div>				
					<div class="right_list" v-if="item.type=='GROUP'" @click.stop="sendmsg(item.groupProfile.groupID,1)">
						<div class="list_img">
							<img :src="item.groupProfile.avatar"   style="object-fit: cover;" class="list_img" alt="" v-if="item.groupProfile.avatar">
							<div class="num" v-if="item.unreadCount>0">{{item.unreadCount}}</div>
						</div>
						
						<div class="list_right">
							<div class="right_user" v-if="item.groupProfile.name">
								{{item.groupProfile.name}}
							</div>
							<div class="right_time">
								<div class="time_msg">
									{{item.lastMessage.nick}}：{{item.lastMessage.messageForShow}}
								</div>
								
								<div style="margin-left: 10px;">{{$util.timestampToTime(item.lastMessage.lastTime)}}</div>
							</div>
						</div>
					</div>
				</div>
				
				
				
			</div>
		</div>
		<el-dialog title="" :visible="true" v-if="outerVisible" :before-close="close">
		    <iframe :src='"/chat/?conversationID="+chatid+"&isgroup="+isgroup' style="width: 100%;height: 600px;"></iframe>
		</el-dialog>
		<div class="msgbtn" @click.stop="sendmsg('')" v-if="tk">
			<img src="../../assets/img/msgbtn.png" style="width: 50px;height: 50px;">
			
			<div class="num" v-if="msgnum>0">{{msgnum}}</div>
		</div>
	</div>
	
</template>

<script>
	import {debounce} from '@/utils/debounce.js';
	
	
	export default {
		data() {
			return {
				userSig:'',
				userID:'',
				isgroup:'',
				SDKAppID:'',
				
				outerVisible:false,
				chatid:'',
				msgnum:'',
				list:[],				
				alllist:[],
				keyword:'',
				tk:'',
				
			};
		},
		inject:['getConfig'],
		computed: {
		    actConfig() {
		      return this.getConfig();
		    },
		},
		watch:{
			actConfig(val) {
			  //console.log('组件监听到的值', val);
			  this.msgnum = val.msgnum;
			  // this.list = val.list;
			  this.alllist = val.list;
			  this.search();
			},
			keyword:{
				handler:function(){
					this.search();
				}
			},
			
		},
		
		async mounted(){
			// console.log('mounted',this.actConfig);
			this.msgnum = this.actConfig.msgnum;
			this.list = this.actConfig.list;
			this.alllist = this.actConfig.list;
			
			this.tk=this.$local.get('t_k');
			
		},
		
		
		methods:{
			
			
			close(){
				this.outerVisible=false;
			},
			search:debounce(function(){
				//console.log(this.keyword);
				if(this.keyword==''){
					this.list=JSON.parse(JSON.stringify(this.alllist));
					return;
				}
				let list=[];
				for (var i = 0; i < this.alllist.length; i++) {
					if(this.alllist[i].type=='C2C'&&this.alllist[i].userProfile.nick.indexOf(this.keyword)>-1){
						list.push(this.alllist[i]);
					}
					if(this.alllist[i].type=='GROUP'&&this.alllist[i].groupProfile.name.indexOf(this.keyword)>-1){
						list.push(this.alllist[i]);
					}
				}
				this.list=list;
				
			}),
			
			sendmsg(id,isgroup){
				this.chatid=id;
				this.isgroup=isgroup||0;
				this.outerVisible=true;
			},
			
			async del(){
				for (var i = 0; i < this.alllist.length; i++) {					
					tim.deleteConversation(this.alllist[i].conversationID);
				}
			},
			
		}
	};
	
	
	
</script>

<style lang="less" scoped>
	.msgbtn{position: fixed;right: 100px;bottom: 100px;z-index: 99;width: 50px;height: 50px;cursor: pointer;
		.num{position: absolute;right: 0;top: 5px;background-color: #B65664;border-radius: 20px;display: flex;align-items: center;justify-content: center;padding:0 5px;height: 20px;font-size: 12px;color: #fff;box-sizing: border-box;min-width: 20px;}
	}
	.home_right{
		width: 470px;
		min-height: 410px;
		border-radius: 3px;
		border: 2px solid #567BB6;
		padding: 30px 0;
		color: #fff;
		.right_title{
			margin:0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 26px;
			.title_left{
				font-size: 21px;
			}
			.title_right{
				display: flex;
				align-items: center;
				.menu1{
					width: 30px;
					height: 19px;
				}
				.menu2{
					width: 27px;
					height: 26px;
					margin-left: 24px;
				}
			}
		}
		.right_search{
			margin:0 30px;
			height: 34px;
			border-radius: 7px;
			border: 1px solid #567BB6;
			display: flex;
			align-items: center;
			padding: 0 16px;
			box-sizing: border-box;
			.search{
				margin-right: 9px;
				width: 18px;
				height: 18px;
			}
			.search_input{
				font-size: 14px;
				border: none;
				background-color: #212121;
				
			}
		}
		.right_list{
			height: 89px;
			margin:0 30px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #979797;
			cursor: pointer;
			.list_img{
				
				margin-right: 20px;
				position: relative;
				.num{position: absolute;right: 0;top: 0;background-color: #B65664;border-radius: 20px;display: flex;align-items: center;justify-content: center;padding:0 5px;height: 20px;font-size: 12px;color: #fff;box-sizing: border-box;min-width: 20px;}
				
				img{width: 52px;
					height: 52px;
					border: 1px solid #979797;
					border-radius: 50%;
				}
			}
			.list_right{
				flex:1;
				font-size: 15px;
				.num{position: absolute;right: 0;top: 5px;background-color: #B65664;border-radius: 20px;display: flex;align-items: center;justify-content: center;padding:0 5px;height: 20px;font-size: 12px;color: #fff;box-sizing: border-box;min-width: 20px;}
				.right_user{
					
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-bottom: 15px;
				}
				.right_time{
					
					font-size: 12px;
					display: flex;
					align-items: center;
					.time_msg{
						flex:1;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			
		}
	
	}
</style>